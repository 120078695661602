export enum FmbrLeftPaneComponentTypeEnum {
  None = 'None',
  FmbrPagesComponent = 'FmbrPagesComponent',
  FmbrControlsPanelComponent = 'FmbrControlsPanelComponent',
  FmbrTemplatesComponent = 'FmbrTemplatesComponent',
  FmbrControlLocatorComponent = 'FmbrControlLocatorComponent',
}

export enum FmbrRightPaneComponentTypeEnum {
  None = 'None',
  FmbrPropertiesComponent = 'FmbrPropertiesComponent',
  FmbrLogicComponent = 'FmbrLogicComponent',
  FmbrAutomationComponent = 'FmbrAutomationComponent',
}

export enum FmbrPropertyTypeEnum {
  None = 'none',
  Group = 'group',
  Row = 'row',
  Information = 'information',
  Input = 'input',
  TextArea = 'textarea',
  Select = 'select',
  Color = 'color',
  SlideToggle = 'slideToggle',
  ButtonToggle = 'buttonToggle',
  DateTime = 'dateTime',
  TextAlignment = 'textAlignment',
  Option = 'option',
  Image = 'image',
  Margin = 'margin',
  Column = 'column',
  TextStyle = 'TextStyle',
}

export enum FmbrPropertyTypeOptionEnum {
  ManualInput = 'manual input',
  FromFile = 'import from file',
  FromApi = 'import from api',
  FromUrl = 'import from url',
}

export enum FmbrControlCategoryEnum {
  Layout = 1,
  Fillable = 2,
  Shapes = 3,
}

export enum FmbrLayoutControlTypeEnum {
  Section = 23,
  Grid = 24,
  Image = 13,
}

export enum FmbrFillableControlTypeEnum {
  Text = 1,
  Signature = 2,
  Email = 3,
  Name = 4,
  Checkbox = 5,
  Date = 6,
  Time = 7,
  Select = 8,
  Address = 9,
  PhoneNumber = 10,
  Radio = 11,
  Rating = 12,
  Image = 13,
  File = 14,
  Table = 15,
}

export enum FmbrShapesControlTypeEnum {
  Rectangle = 25,
  Circle = 26,
  Square = 27,
  Triangle = 28,
  Star = 29,
  Arrow = 30,
}

export enum FmbrPageOrientationEnum {
  Portrait = 'Portrait',
  Landscape = 'Landscape',
}

export enum FmbrPageSizeEnum {
  Letter = 'Letter',
  A4 = 'A4',
}

export enum ProjectStatus {
  Draft = 1,
  PendingApproval = 2,
  Approved = 3,
  Rejected = 4,
  Published = 5,
  Expired = 6,
}

export enum ProjectPublishType {
  saveonly = 1,
  publishandsendmail = 2,
}

export enum TemplateFilter {
  All = 1,
  CreatedByMe = 2,
  CreatedByOthers = 3,
}
