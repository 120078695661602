import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthRoutingModule } from './auth-routing.module';
import { InviteComponent } from './invite/invite.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { LinkedInResponseComponent } from './linked-in-response/linked-in-response.component';
import { ThirdPartyErrorComponent } from './modals/third-party-error/third-party-error.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { WelcomeOnboardComponent } from './welcome-onboard/welcome-onboard.component';
import { IssueComponent } from './issue/issue.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRippleModule } from '@angular/material/core';
import { GeneralLoaderModule } from '../@core/shared/loaders/general-loader/general-loader.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NameInitialsModule } from '../@core/pipes/name-initials/name-initials.module';
import { RandomColorModule } from '../@directives/random-color/random-color.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { NotificationModule } from '../@core/shared/notification/notification.module';
import { DeviceVerificationComponent } from './device-verification/device-verification.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    InviteComponent,
    EmailConfirmationComponent,
    LinkedInResponseComponent,
    ThirdPartyErrorComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    WelcomeOnboardComponent,
    IssueComponent,
    DeviceVerificationComponent,
    OtpVerificationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRippleModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    GeneralLoaderModule,
    NameInitialsModule,
    RandomColorModule,
    NotificationModule,
    MatProgressBarModule,
  ],
})
export class AuthModule {}
