import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as GeneralActions from '../general/general.actions';
import { of } from 'rxjs';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
  // combineLatestWith,
} from 'rxjs/operators';
import * as EditorActions from './editor.actions';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { EditorService } from '../../services/editor.service';
import { MatDialog } from '@angular/material/dialog';
import { DocumentType, RecipientCategoryEnum } from '../../enums';
import { NotificationService } from '../../services/notification.service';
import {
  CUSTOM_BEARER_TOKEN,
  USE_ACCESS_TOKEN,
  USE_CUSTOM_BEARER_TOKEN,
  USE_DEVELOPER_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { convertToFormData, sortAndMapTableData } from '../../utils/helpers';
import { HelperService } from '../../services/helper.service';
import * as FlowmonoAssistantActions from '../flowmono-assistant/flowmono-assistant.actions';
declare let require: any;
const FileSaver = require('file-saver');

@Injectable()
export class EditorEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private editorService: EditorService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(EditorActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  uploadDocuments$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.UploadDocuments),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/uploaddocuments`,
              {
                ...editorData.payload,
              },
              {
                context: editorData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${editorData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Editor] Upload Documents Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Upload Documents',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Upload Documents ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  uploadDocumentsUsingIformFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.UploadDocumentsUsingIformFile),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAlternateDocumentUrl}/Documents/uploaddocumentsusingiformfile`,
              convertToFormData(editorData.payload),
              {
                context: editorData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${editorData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message:
                      resData.message || 'Document upload was successful',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Editor] Upload Documents Using Iform File Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message:
                      resData.message ||
                      resData.error ||
                      resData.exceptionError ||
                      'An error ocurred while uploading. Please try again',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Upload Documents Using Iform File',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Upload Documents Using Iform File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getDocumentsBySigningRequestId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.GetDocumentsBySigningRequestId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/getdocumentsbysigningrequestid/${editorData.payload.signingRequestId}/${editorData.payload.subscriberId}/${editorData.payload.userId}`,
            {
              context: editorData.additionalPayload?.bearerToken
                ? new HttpContext()
                    .set(USE_CUSTOM_BEARER_TOKEN, true)
                    .set(
                      CUSTOM_BEARER_TOKEN,
                      `Bearer ${editorData.additionalPayload?.bearerToken}`
                    )
                : new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(EditorActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return EditorActions.SaveDocumentsBySigningRequestId({
                  payload: resData?.entity?.documents,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return EditorActions.SaveDocumentsBySigningRequestId({
                  payload: [],
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Editor][CatchError] Failed To Get Documents By Signing Request Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  updateDocumentData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.UpdateDocumentData),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          const { subscriberId, userId } = editorData.payload;

          // const payload = {
          //   encryptedRequest: this.helperService.AES_Encryption_Decryption(
          //     'encrypt',
          //     JSON.stringify(editorData.payload)
          //   ),
          //   subscriberId: subscriberId,
          //   userId: userId,
          // };

          const payload = {
            encryptedRequest: this.helperService.encryptData(
              JSON.stringify(editorData.payload)
            ),
            subscriberId: subscriberId,
            userId: userId,
          };

          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/updatedocumentdata`,
              payload,
              {
                context: editorData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${editorData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Update Document Data Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Update Document Data',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Update Document Data ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  selfSignDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.SelfSignDocuments),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAlternateDocumentUrl}/Documents/selfsigndocuments`,
            // `${environment.onyxDocDocumentUrl}/Documents/selfsigndocuments`,
            editorData.payload,
            {
              context: editorData.additionalPayload?.bearerToken
                ? new HttpContext()
                    .set(USE_CUSTOM_BEARER_TOKEN, true)
                    .set(
                      CUSTOM_BEARER_TOKEN,
                      `Bearer ${editorData.additionalPayload?.bearerToken}`
                    )
                : new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(EditorActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message:
                    resData.message || 'Document was signed successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(EditorActions.IsLoading({ payload: true }));

                // resData?.entity?.documents.forEach((document: any) => {
                //   FileSaver?.saveAs(
                //     document.documentFile,
                //     `Flowmono-${document.documentName}`
                //   );

                //   // if (
                //   //   (document.documentName as string).endsWith(
                //   //     document.documentExtension!
                //   //   )
                //   // ) {
                //   //   FileSaver?.saveAs(
                //   //     document.documentFile,
                //   //     `Flowmono-${document.documentName}`
                //   //   );
                //   // } else {
                //   //   FileSaver?.saveAs(
                //   //     document.documentFile,
                //   //     `Flowmono-${document.documentName}.${document.documentExtension}`
                //   //   );
                //   // }
                // });

                this.store.dispatch(
                  EditorActions.GetDocumentsBySigningRequestId({
                    payload: {
                      signingRequestId: editorData.payload.signingRequestId,
                      userId: editorData.payload.userId,
                      subscriberId: editorData.payload.subscriberId,
                    },
                    additionalPayload: editorData.additionalPayload?.bearerToken
                      ? {
                          bearerToken:
                            editorData.additionalPayload?.bearerToken,
                        }
                      : undefined,
                  })
                );

                return {
                  type: '[Editor] Self Sign Documents Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message:
                    resData.message ||
                    resData.error ||
                    resData.exceptionError ||
                    'An error occurred while self signing. PLease try again',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Editor] Failed To Self Sign Documents',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Editor][CatchError] Failed To Self Sign Documents ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  replaceDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.ReplaceDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAlternateDocumentUrl}/Documents/replacedocument`,
            // `${environment.onyxDocDocumentUrl}/Documents/replacedocument`,
            editorData.payload,
            {
              context: editorData.additionalPayload?.bearerToken
                ? new HttpContext()
                    .set(USE_CUSTOM_BEARER_TOKEN, true)
                    .set(
                      CUSTOM_BEARER_TOKEN,
                      `Bearer ${editorData.additionalPayload?.bearerToken}`
                    )
                : new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(EditorActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message:
                    resData.message || 'Document was successfully replaced',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(EditorActions.IsLoading({ payload: true }));

                this.store.dispatch(
                  EditorActions.GetDocumentsBySigningRequestId({
                    payload: {
                      signingRequestId: editorData.payload.signingRequestId,
                      userId: editorData.payload.userId,
                      subscriberId: editorData.payload.subscriberId,
                    },
                    additionalPayload: editorData.additionalPayload?.bearerToken
                      ? {
                          bearerToken:
                            editorData.additionalPayload?.bearerToken,
                        }
                      : undefined,
                  })
                );

                this.store.dispatch(
                  FlowmonoAssistantActions.IngestFile({
                    payload: {
                      documentId: editorData.payload.existingDocumentId,
                      isDocumentReplaced: true,
                    },
                  })
                );

                return {
                  type: '[Editor] Replace Document Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message:
                    resData.message ||
                    resData.error ||
                    resData.exceptionError ||
                    'An error occurred while replacing document. Please try again',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Editor] Failed To Replace Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Editor][CatchError] Failed To Replace Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  removeDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.RemoveDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/removedocument`,
            editorData.payload,
            {
              context: editorData.additionalPayload?.bearerToken
                ? new HttpContext()
                    .set(USE_CUSTOM_BEARER_TOKEN, true)
                    .set(
                      CUSTOM_BEARER_TOKEN,
                      `Bearer ${editorData.additionalPayload?.bearerToken}`
                    )
                : new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(EditorActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(EditorActions.IsLoading({ payload: true }));

                this.store.dispatch(
                  EditorActions.GetDocumentsBySigningRequestId({
                    payload: {
                      signingRequestId: editorData.payload.signingRequestId,
                      userId: editorData.payload.userId,
                      subscriberId: editorData.payload.subscriberId,
                    },
                    additionalPayload: editorData.additionalPayload?.bearerToken
                      ? {
                          bearerToken:
                            editorData.additionalPayload?.bearerToken,
                        }
                      : undefined,
                  })
                );

                return {
                  type: '[Editor] Remove Document Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Editor] Failed To Remove Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Editor][CatchError] Failed To Remove Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  downloadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.DownloadDocuments),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAlternateDocumentUrl}/Documents/downloaddocuments`,
            // `${environment.onyxDocDocumentUrl}/Documents/downloaddocuments`,
            editorData.payload,
            {
              context: editorData.additionalPayload?.bearerToken
                ? new HttpContext()
                    .set(USE_CUSTOM_BEARER_TOKEN, true)
                    .set(
                      CUSTOM_BEARER_TOKEN,
                      `Bearer ${editorData.additionalPayload?.bearerToken}`
                    )
                : new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(EditorActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message:
                    resData.message || 'Documents were downloaded succesfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(EditorActions.IsLoading({ payload: true }));

                resData?.entity?.documents.forEach((document: any) => {
                  FileSaver?.saveAs(
                    document.documentFile,
                    `Flowmono-${document.documentName}`
                  );
                  // if (
                  //   (document.documentName as string).endsWith(
                  //     document.documentExtension
                  //   )
                  // ) {
                  //   FileSaver?.saveAs(
                  //     document.documentFile,
                  //     `Flowmono-${document.documentName}`
                  //   );
                  // } else {
                  //   FileSaver?.saveAs(
                  //     document.documentFile,
                  //     `Flowmono-${document.documentName}.${document.documentExtension}`
                  //   );
                  // }
                });

                this.store.dispatch(
                  EditorActions.GetDocumentsBySigningRequestId({
                    payload: {
                      signingRequestId: editorData.payload.signingRequestId,
                      userId: editorData.payload.userId,
                      subscriberId: editorData.payload.subscriberId,
                    },
                    additionalPayload: editorData.additionalPayload?.bearerToken
                      ? {
                          bearerToken:
                            editorData.additionalPayload?.bearerToken,
                        }
                      : undefined,
                  })
                );

                return {
                  type: '[Editor] Download Documents Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message:
                    resData.message ||
                    resData.error ||
                    resData.exceptionError ||
                    'An error occurred while downloading documents. Please try again',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Editor] Failed To Download Documents',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Editor][CatchError] Failed To Download Documents ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  shareSelfSignedDocuments$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.ShareSelfSignedDocuments),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAlternateDocumentUrl}/Documents/signandacceptdocument`, // `${environment.onyxDocDocumentUrl}/Documents/shareselfsigneddocuments`,
              editorData.payload,
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
              // {
              //   context: editorData.additionalPayload?.bearerToken
              //     ? new HttpContext()
              //         .set(USE_CUSTOM_BEARER_TOKEN, true)
              //         .set(
              //           CUSTOM_BEARER_TOKEN,
              //           `Bearer ${editorData.additionalPayload?.bearerToken}`
              //         )
              //     : new HttpContext().set(USE_ACCESS_TOKEN, true),
              // }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || 'Share document was successful',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Editor] Share Self Signed Documents Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message:
                      resData.message ||
                      resData.error ||
                      resData.exceptionError ||
                      'An error occurred while sharing document. Please try again',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Share Self Signed Documents',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Share Self Signed Documents ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteRecipient$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.DeleteRecipient),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Recipients/delete`,
              editorData?.payload,
              {
                context: editorData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${editorData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    EditorActions.IsLoading({ payload: true })
                  );

                  this.store.dispatch(
                    EditorActions.GetDocumentsBySigningRequestId({
                      payload: {
                        signingRequestId: editorData.payload.signingRequestId,
                        userId: editorData.additionalPayload.userId,
                        subscriberId: editorData.payload.subscriberId,
                      },
                      additionalPayload: editorData.additionalPayload
                        ?.bearerToken
                        ? {
                            bearerToken:
                              editorData.additionalPayload?.bearerToken,
                          }
                        : undefined,
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Delete Recipient Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Delete Recipient',
                  });
                }

                return {
                  resData: resData,
                  recipientIndex: editorData.additionalPayload.recipientIndex,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Delete Recipient ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  saveAsDraft$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.SaveAsDraft),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/updatemultipledocumentscontrols`,
            editorData?.payload,
            {
              context: editorData.additionalPayload?.bearerToken
                ? new HttpContext()
                    .set(USE_CUSTOM_BEARER_TOKEN, true)
                    .set(
                      CUSTOM_BEARER_TOKEN,
                      `Bearer ${editorData.additionalPayload?.bearerToken}`
                    )
                : new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(EditorActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                // const notification: Notification = {
                //   state: 'success',
                //   message: resData.message || resData.messages[0],
                // };

                // this.notificationService.openSnackBar(
                //   notification,
                //   'flwmn-notification-success'
                // );

                this.store.dispatch(EditorActions.IsLoading({ payload: true }));

                this.store.dispatch(
                  EditorActions.GetDocumentsBySigningRequestId({
                    payload: {
                      signingRequestId: editorData.payload.signingRequestId,
                      userId: editorData.payload.userId,
                      subscriberId: editorData.payload.subscriberId,
                    },
                    additionalPayload: editorData.additionalPayload?.bearerToken
                      ? {
                          bearerToken:
                            editorData.additionalPayload?.bearerToken,
                        }
                      : undefined,
                  })
                );

                return {
                  type: '[Editor] Save As Draft Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Editor] Failed To Save As Draft',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Editor][CatchError] Failed To Save As Draft ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  sendToSignatories$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.SendToSignatories),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          const { subscriberId, userId } = editorData.payload;

          const payload = {
            encryptedRequest: this.helperService.encryptData(
              JSON.stringify(editorData.payload)
            ),
            subscriberId: subscriberId,
            userId: userId,
          };

          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/sendtosignatories`,
              payload,
              {
                context: editorData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${editorData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Editor] Send To Signatories Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Send To Signatories',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Send To Signatories ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  resendToSignatories$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.ResendToSignatories),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/resendtosignatories`,
              editorData?.payload,
              {
                context: editorData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${editorData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Editor] Resend To Signatories Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Resend To Signatories',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Resend To Signatories ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getRecipientsBySigningRequestId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.GetRecipientsBySigningRequestId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Recipients/getrecipientsbysigningrequestid/${editorData.payload.signingRequestId}/${editorData.payload.subscriberId}/${editorData.payload.userId}`,
            {
              context: editorData.additionalPayload?.bearerToken
                ? new HttpContext()
                    .set(USE_CUSTOM_BEARER_TOKEN, true)
                    .set(
                      CUSTOM_BEARER_TOKEN,
                      `Bearer ${editorData.additionalPayload?.bearerToken}`
                    )
                : new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(EditorActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                this.store.dispatch({
                  type: '[Editor] Get Recipients By Signing Request Id Was Successful',
                });

                const actorsData: any[] = [];

                sortAndMapTableData(resData?.entity, 'asc', 'rank', [
                  'createdDate',
                  'lastModifiedDate',
                ])?.forEach((el: any) => {
                  if (
                    el?.recipientCategoryEnum === RecipientCategoryEnum.Signer
                  ) {
                    actorsData.push({
                      actorId: el?.id,
                      actorFirstName: el?.firstName,
                      actorLastName: el?.lastName,
                      actorEmail: el?.email,
                    });
                  }
                });

                return EditorActions.SaveControlActors({
                  payload: {
                    actorListName: 'Recipient',
                    actorsData: actorsData,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch({
                  type: '[Editor] Failed To Get Recipients By Signing Request Id',
                });

                return EditorActions.SaveControlActors({
                  payload: null,
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Editor][CatchError] Failed To Get Recipients By Signing Request Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  decodeUrlHash$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.DecodeUrlHash),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/decodeurlhash/hash/sharedDocument?documentHash=${editorData.payload.documentHash}&sharedDocument=${editorData.payload.isSharedDocument}`,
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Decode Url Has Was Successful',
                  });
                  this.store.dispatch(
                    EditorActions.SaveDecodeUrlHash({ payload: resData.entity })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Decode Url Hash',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Decode Url Hash ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getApiUserById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.GetApiUserById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocAuthUrl}/Users/getapiuserbyid/${editorData.payload.userId}`,
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Get Api User By Id Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Get Api User By Id',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Get Api User By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  signAndAccept$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.SignAndAccept),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          const { subscriberId, userId } = editorData.payload;

          const payload = {
            encryptedRequest: this.helperService.encryptData(
              JSON.stringify(editorData.payload)
            ),
            subscriberId: subscriberId,
            userId: userId,
          };

          // const payload = {
          //   encryptedRequest: this.helperService.AES_Encryption_Decryption(
          //     'encrypt',
          //     JSON.stringify(editorData.payload)
          //   ),
          // };

          return this.http
            .post(
              `${environment.onyxDocAlternateDocumentUrl}/Documents/signandacceptdocument`,
              // `${environment.onyxDocDocumentUrl}/Documents/signandacceptdocument`,
              payload,
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || 'Sign and accept was succesful',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );

                  if (authState.user) {
                    this.router.navigate(['/app']);
                  } else {
                    this.router.navigate(['/']);
                  }

                  this.store.dispatch({
                    type: '[Editor] Sign And Accept Documents Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message:
                      resData.message ||
                      resData.error ||
                      resData.exceptionError ||
                      'An error occurred while signing and accepting. Please try again',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Sign And Accept Documents',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Sign And Accept Documents ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  inAppUserLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.InAppUserLogin),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ApplicationAuth/inappuserlogin`,
            editorData?.payload,
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(EditorActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                window.location.href = resData.entity;

                return {
                  type: '[Editor] In App User Login Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return {
                  type: '[Editor] Failed To In App User Login',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Editor][CatchError] Failed To In App User Login ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  rejectDocument$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.RejectDocument),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          const payload = {
            encryptedRequest: this.helperService.encryptData(
              JSON.stringify(editorData.payload)
            ),
          };

          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/RecipientActions/rejectmultipledocuments`,
              payload,
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );

                  if (
                    editorData.additionalPayload
                      .stopAfterAnyDocumentRejection === false
                  ) {
                    location.reload();
                  } else {
                    this.router.navigate(['/app/dashboard']);
                  }

                  this.store.dispatch({
                    type: '[Editor] Reject Document Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Reject Document',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Reject Document ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  recallDocumentsBySigningRequestId$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.RecallDocumentsBySigningRequestId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          const { subscriberId, userId } = editorData.payload;

          const payload = {
            encryptedRequest: this.helperService.encryptData(
              JSON.stringify(editorData.payload)
            ),
            subscriberId: subscriberId,
            userId: userId,
          };

          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/recallsigningrequest`,
              payload,
              {
                context: editorData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${editorData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );

                  this.store.dispatch({
                    type: '[Editor] Reject Document Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Reject Document',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Reject Document ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  renameDocument$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.RenameDocument),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/updatedocumentname`,
              editorData?.payload,
              {
                context: editorData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${editorData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Editor] Rename Document Was Successful',
                  });

                  this.store.dispatch(
                    EditorActions.IsLoading({ payload: true })
                  );

                  this.store.dispatch(
                    EditorActions.GetDocumentsBySigningRequestId({
                      payload: {
                        signingRequestId:
                          editorData.additionalPayload!.signingRequestId!,
                        userId: editorData.payload.userId,
                        subscriberId: editorData.payload.subscriberId,
                      },
                      additionalPayload: editorData.additionalPayload
                        ?.bearerToken
                        ? {
                            bearerToken:
                              editorData.additionalPayload?.bearerToken,
                          }
                        : undefined,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Rename Document',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Rename Document ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteDocumentControlsByControlId$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.DeleteDocumentControlsByControlId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/deletedocumentcontrolsbycontrolid`,
              editorData?.payload,
              {
                context: editorData.additionalPayload?.bearerToken
                  ? new HttpContext()
                      .set(USE_CUSTOM_BEARER_TOKEN, true)
                      .set(
                        CUSTOM_BEARER_TOKEN,
                        `Bearer ${editorData.additionalPayload?.bearerToken}`
                      )
                  : new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Editor] Delete Document Control Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Delete Document Control',
                  });
                }

                return {
                  resData: resData,
                  controlRemoveData:
                    editorData?.additionalPayload?.controlRemoveData,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Delete Document Control ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getUserByEmail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.GetUserByEmail),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocAuthUrl}/Users/getbyemail/${editorData.payload.email}/${editorData.payload.userId}`,
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Get User By Email Was Successful',
                  });

                  this.store.dispatch(
                    EditorActions.SaveInternalUser({ payload: resData })
                  );
                } else {
                  // const notification: Notification = {
                  //   state: 'error',
                  //   message: resData.message || resData.messages[0],
                  // };

                  // this.notificationService.openSnackBar(
                  //   notification,
                  //   'flwmn-notification-error'
                  // );

                  this.store.dispatch({
                    type: '[Editor] Failed To Get User By Email',
                  });

                  this.store.dispatch(
                    EditorActions.GetExternalUserByEmail({
                      payload: {
                        email: editorData.payload.email,
                      },
                    })
                  );
                }

                return {
                  resData: resData,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Get User By Email ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  /**********************************************************************************/

  private handleError = (errorRes: any) => {
    // this.store.dispatch(
    //   GeneralActions.SetNotification({
    //     payload: {
    //       isSuccess: false,
    //       message: 'Connection error! Please contact support',
    //     },
    //   })
    // );

    this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

    return of({ type: '[Editor] Error' });
  };

  uploadDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.UploadDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/uploaddocument`,
            {
              ...editorData.payload,
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                if (editorData.payload.documentType === DocumentType.Template) {
                  this.store.dispatch(
                    GeneralActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: true,
                        message:
                          'Document was successfully saved as a template',
                      },
                    })
                  );

                  return {
                    type: '[Editor] Save As Template Was Successful',
                  };
                } else {
                  const data = btoa(
                    JSON.stringify([
                      `documentId = ${resData.entity.documentId}`,
                      // `file = ${resData.entity.file}`,
                      // `name = ${resData.entity.name}`,
                    ])
                  );

                  this.router.navigate([], {
                    queryParams: { data: data },
                    queryParamsHandling: 'merge',
                  });

                  return {
                    type: '[Editor] Upload Document Was Successful',
                  };

                  // return EditorActions.SaveDocument({
                  //   payload: {
                  //     documentId: resData.entity.documentId,
                  //     file: resData.entity.file,
                  //     name: resData.entity.name,
                  //   },
                  // });
                }
              } else {
                this.store.dispatch(
                  GeneralActions.IsLoading({ payload: false })
                );

                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message: 'Failed To Upload Document. Please try again.',
                    },
                  })
                );

                return {
                  type: '[Editor] Failed To Upload Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  getDocumentById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.GetDocumentById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/getbyid/${editorData.payload.id}/${authState.user.UserId}`
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Get Document By Id Was Successful',
                  });
                } else {
                  this.store.dispatch(
                    GeneralActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Get Document By Id',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  // replaceDocument$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(EditorActions.ReplaceDocument),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([documentData, authState]) => {
  //       return this.http
  //         .post<GenericResponse>(
  //           `${environment.onyxDocDocumentUrl}/Documents/replacedocument`,
  //           {
  //             ...documentData.payload,
  //             userId: authState.user.UserId,
  //             subscriberId: authState.user.SubscriberId,
  //           }
  //         )
  //         .pipe(
  //           map((resData) => {
  //             this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

  //             if (resData.succeeded === true) {
  //               // this.store.dispatch(DocumentActions.GetAllFolders());

  //               this.store.dispatch(
  //                 GeneralActions.SetNotification({
  //                   payload: {
  //                     isSuccess: true,
  //                     message: 'Document was successfully replaced',
  //                   },
  //                 })
  //               );

  //               location.reload();

  //               // const currentUrl = this.router.url;
  //               // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //               // this.router.onSameUrlNavigation = 'reload';
  //               // this.router.navigateByUrl(currentUrl);

  //               return {
  //                 type: '[Editor] Replace Document Was Successful ',
  //               };
  //             } else {
  //               this.store.dispatch(
  //                 GeneralActions.IsLoading({ payload: false })
  //               );

  //               this.store.dispatch(
  //                 GeneralActions.SetNotification({
  //                   payload: {
  //                     isSuccess: false,
  //                     message: 'Failed To Replace Document. Please try again.',
  //                   },
  //                 })
  //               );

  //               return {
  //                 type: '[Editor] Failed To Replace Document',
  //               };
  //             }
  //           }),
  //           catchError((errorRes: any) => {
  //             return this.handleError(errorRes);
  //           })
  //         );
  //     })
  //   );
  // });

  downloadDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.DownloadDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/downloaddocument`,
            {
              ...editorData.payload,
            }
          )
          .pipe(
            map((resData) => {
              // this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: true,
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return {
                  type: '[Editor] Download Document Was Successful',
                };
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return {
                  type: '[Editor] Failed To Download Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  verifyPasscode$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.VerifyPasscode),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/DocumentPassCode/verifypasscode`,
              {
                ...editorData.payload,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Verify Passcode Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Verify Passcode',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getRecipientsByDocumentId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.GetRecipientsByDocumentId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Recipients/getrecipientsbydocumentid/${editorData.payload.documentId}`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                return EditorActions.SaveRecipients({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch(
                  EditorActions.SaveRecipients({
                    payload: [],
                  })
                );

                return {
                  type: '[Editor] Failed To Get Recipients By Document Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  createRecipients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.CreateRecipients),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Recipients/createrecipients`,
            {
              subscriberId: editorData.payload.subscriberId,
              recipients: editorData.payload.recipients,
              userId: editorData.payload.userId,
              documentId: editorData.payload.documentId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                this.store.dispatch(
                  EditorActions.GetRecipientsByDocumentId({
                    payload: {
                      documentId: editorData.payload.documentId,
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Editor] Create Recipients Was Successful',
                };

                // this.store.dispatch({
                //   type: '[Editor] Create Recipients Was Successful',
                // });

                // this.store.dispatch(
                //   EditorActions.SaveRecipients({ payload: resData.entity })
                // );

                // return resData;
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return {
                  type: '[Editor] Failed To Create Recipients',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  updateRecipients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.UpdateRecipients),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Recipients/updaterecipients`,
            {
              subscriberId: editorData.payload.subscriberId,
              recipients: editorData.payload.recipients,
              userId: editorData.payload.userId,
              documentId: editorData.payload.documentId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                this.store.dispatch(
                  EditorActions.GetRecipientsByDocumentId({
                    payload: {
                      documentId: editorData.payload.documentId,
                    },
                  })
                );

                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: true,
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Editor] Update Recipients Was Successful',
                };
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return {
                  type: '[Editor] Failed To Update Recipients',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  deleteRecipientsOld$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.DeleteRecipientOld),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Recipients/delete/${editorData.payload.organizationId}/${editorData.payload.recipientId}`,
              {}
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  GeneralActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    EditorActions.GetRecipientsByDocumentId({
                      payload: {
                        documentId: editorData.payload.documentId,
                      },
                    })
                  );

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: true,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Delete Recipient Was Successful',
                  });
                } else {
                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Delete Recipient',
                  });
                }

                return {
                  response: resData,
                  index: editorData.payload.index,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  // saveAsDraft$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(EditorActions.SaveAsDraft),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([editorData, authState]) => {
  //       return this.http
  //         .post<GenericResponse>(
  //           `${environment.onyxDocDocumentUrl}/Documents/saveAsdraft`,
  //           {
  //             subscriberId: authState.user.SubscriberId,
  //             userId: authState.user.UserId,
  //             ...editorData.payload,
  //           }
  //         )
  //         .pipe(
  //           map((resData: any) => {
  //             this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

  //             if (resData.succeeded === true) {
  //               this.store.dispatch(
  //                 GeneralActions.SetNotification({
  //                   payload: {
  //                     isSuccess: true,
  //                     message: resData.message || resData.messages[0],
  //                   },
  //                 })
  //               );

  //               return {
  //                 type: '[Editor] Save As Draft Was Successful',
  //               };
  //             } else {
  //               this.store.dispatch(
  //                 GeneralActions.SetNotification({
  //                   payload: {
  //                     isSuccess: false,
  //                     message: resData.message || resData.messages[0],
  //                   },
  //                 })
  //               );

  //               return {
  //                 type: '[Editor] Failed To Save As Draft',
  //               };
  //             }
  //           }),
  //           catchError((errorRes: any) => {
  //             return this.handleError(errorRes);
  //           })
  //         );
  //     })
  //   );
  // });

  // shareSelfSignedDocument$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(EditorActions.ShareSelfSignedDocument),
  //       withLatestFrom(this.store.select('auth')),
  //       switchMap(([editorData, authState]) => {
  //         return this.http
  //           .post<GenericResponse>(
  //             `${environment.onyxDocDocumentUrl}/Documents/shareselfsigneddocument`,
  //             editorData.payload
  //           )
  //           .pipe(
  //             map((resData: any) => {
  //               this.dialog.closeAll();

  //               this.store.dispatch(
  //                 GeneralActions.IsLoading({ payload: false })
  //               );

  //               if (resData.succeeded === true) {
  //                 this.store.dispatch(
  //                   GeneralActions.SetNotification({
  //                     payload: {
  //                       isSuccess: true,
  //                       message: resData.message || resData.messages[0],
  //                     },
  //                   })
  //                 );

  //                 this.store.dispatch({
  //                   type: '[Editor] Share Self Signed Document Was Successful',
  //                 });

  //                 // if (this.router.url.includes('/editor')) {
  //                 //   this.router.navigate(['/']);
  //                 // } else if (this.router.url.includes('/document/e/create')) {
  //                 //   this.router.navigate(['/app/document']);
  //                 // }

  //                 this.store.dispatch(
  //                   EditorActions.ClearDocumentAndRecipients()
  //                 );

  //                 return {
  //                   response: resData,
  //                   documentId: editorData.payload.documentId,
  //                 };
  //               } else {
  //                 this.store.dispatch(
  //                   GeneralActions.SetNotification({
  //                     payload: {
  //                       isSuccess: false,
  //                       message: resData.message || resData.messages[0],
  //                     },
  //                   })
  //                 );

  //                 this.store.dispatch({
  //                   type: '[Editor] Failed To Share Self Signed Document',
  //                 });

  //                 return {
  //                   response: resData,
  //                   documentId: editorData.payload.documentId,
  //                 };
  //               }
  //             }),
  //             catchError((errorRes: any) => {
  //               return this.handleError(errorRes);
  //             })
  //           );
  //       }),
  //       share()
  //     );
  //   },
  //   { dispatch: false }
  // );

  sendDocumentToSignatories$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.SendDocumentToSignatories),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/senddocumenttosignatories`,
              editorData.payload
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  GeneralActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.dialog.closeAll();

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: true,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Send To Document Signatories Was Successful',
                  });

                  if (this.router.url.includes('/editor')) {
                    this.router.navigate(['/'], {
                      state: { bypassGuard: true },
                    });
                  } else if (this.router.url.includes('/document/e/create')) {
                    this.router.navigate(['/app/document'], {
                      state: { bypassGuard: true },
                    });
                  }

                  this.store.dispatch(
                    EditorActions.ClearDocumentAndRecipients()
                  );

                  return {
                    response: resData,
                    documentId: editorData.payload.documentId,
                  };
                } else {
                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Send To Document Signatories',
                  });

                  return {
                    response: resData,
                    documentId: editorData.payload.documentId,
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getUserVerificationStatus$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.GetUserVerificationStatus),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocAuthUrl}/Users/getuserverificationstatus/${editorData.payload.userId}`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] User Is Verified',
                  });

                  return {
                    succeeded: true,
                    instance: editorData.payload.instance,
                  };
                } else {
                  this.store.dispatch({
                    type: '[Editor] User is Unverified',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message:
                          'Your email has not been verified, please go to your email and verify it to continue',
                        // message:
                        //   resData.messages === null
                        //     ? resData.message
                        //     : resData.messages[0],
                      },
                    })
                  );

                  return {
                    succeeded: false,
                    instance: editorData.payload.instance,
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  // updateComponent$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(EditorActions.UpdateComponents),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([editorData, authState]) => {
  //       return this.http
  //         .post<GenericResponse>(
  //           `${environment.onyxDocDocumentUrl}/Documents/updatecomponent`,
  //           {
  //             subscriberId: editorData.payload.subscriberId,
  //             documentId: editorData.payload.documentId,
  //             email: editorData.payload.email,
  //             components: editorData.payload.components,
  //           }
  //         )
  //         .pipe(
  //           map((resData: any) => {
  //             this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

  //             if (resData.succeeded === true) {
  //               this.store.dispatch({
  //                 type: '[Editor] Update Component Document Was Successful',
  //               });

  //               this.store.dispatch(
  //                 GeneralActions.IsLoading({ payload: true })
  //               );

  //               return EditorActions.SignAndAcceptDocument({
  //                 payload: {
  //                   documentId: editorData.payload.documentId,
  //                   signedFile: editorData.payload.signedFile!,
  //                   mimeType: editorData.payload.mimeType!,
  //                   extension: editorData.payload.extension!,
  //                   recipientEmail: editorData.payload.recipientEmail,
  //                   signingAPIUrl: editorData.payload.signingAPIUrl,
  //                 },
  //               });
  //             } else {
  //               this.store.dispatch(
  //                 GeneralActions.SetNotification({
  //                   payload: {
  //                     isSuccess: false,
  //                     message:
  //                       resData.messages === null
  //                         ? resData.message
  //                         : resData.messages[0],
  //                   },
  //                 })
  //               );

  //               return {
  //                 type: '[Editor] Failed To Update Component Document',
  //               };
  //             }
  //           }),
  //           catchError((errorRes: any) => {
  //             return this.handleError(errorRes);
  //           })
  //         );
  //     })
  //   );
  // });

  // signAndAcceptDocument$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(EditorActions.SignAndAcceptDocument),
  //       withLatestFrom(this.store.select('auth')),
  //       switchMap(([editorData, authState]) => {
  //         return this.http
  //           .post<GenericResponse>(
  //             `${environment.onyxDocDocumentUrl}/Documents/signandacceptdocument`,
  //             editorData.payload
  //           )
  //           .pipe(
  //             map((resData: any) => {
  //               this.store.dispatch(
  //                 GeneralActions.IsLoading({ payload: false })
  //               );

  //               if (resData.succeeded === true) {
  //                 this.store.dispatch({
  //                   type: '[Editor] Sign And Accept Document Was Successful',
  //                 });

  //                 this.store.dispatch(
  //                   GeneralActions.SetNotification({
  //                     payload: {
  //                       isSuccess: true,
  //                       message: resData.message || resData.messages[0],
  //                     },
  //                   })
  //                 );

  //                 return resData;
  //               } else {
  //                 this.store.dispatch({
  //                   type: '[Editor] Failed To Sign And Accept Document',
  //                 });

  //                 this.store.dispatch(
  //                   GeneralActions.SetNotification({
  //                     payload: {
  //                       isSuccess: false,
  //                       message: resData.message || resData.messages[0],
  //                     },
  //                   })
  //                 );

  //                 return resData;
  //               }
  //             }),
  //             catchError((errorRes: any) => {
  //               return this.handleError(errorRes);
  //             })
  //           );
  //       }),
  //       share()
  //     );
  //   },
  //   { dispatch: false }
  // );

  getCommentsByDocumentId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.GetCommentsByDocumentId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Comments/getcommentsbydocumentid/${editorData.payload.subscriberId}/${editorData.payload.documentId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                this.store.dispatch(
                  EditorActions.SaveCommentsByDocumentId({
                    payload: resData.entity,
                  })
                );

                return {
                  type: '[Editor] Get Comment By Document Id Was Successful',
                };
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message:
                        resData.messages === null
                          ? resData.message
                          : resData.messages[0],
                    },
                  })
                );

                return {
                  type: '[Editor] Failed To Get Comment By Document Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  createComment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.CreateComment),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Comments/create`,
              editorData.payload
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  GeneralActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Create Comment Was Successful',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: true,
                        message: 'Comment created successfully',
                      },
                    })
                  );

                  this.editorService.removeAllComments.next('');

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Editor] Failed To Create Comment',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getControls$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditorActions.GetControls),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocFormBuilderUrl}/Controls/getcontrols/${authState.user.SubscriberId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    GeneralActions.IsLoading({
                      payload: false,
                    })
                  );

                  return this.store.dispatch(
                    EditorActions.SaveControls({
                      payload: resData.entity,
                    })
                  );
                } else {
                  this.store.dispatch({
                    type: '[Editor] Control config failed',
                  });

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      ),

    { dispatch: false }
  );

  getActiveControls$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.GetActiveControls),
      switchMap((editorData) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocFormBuilderUrl}/Controls/getactivecontrols/${editorData.payload.subscriberId}/${editorData.payload.userId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return EditorActions.SaveActiveControls({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch({
                  type: '[Editor] Failed To Get Active Controls',
                });

                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return { type: '[Editor] Failed To Get Active Controls' };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  createControl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.CreateControl),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocFormBuilderUrl}/Controls/create`,
              {
                ...editorData.payload,
                subscriberId: editorData.payload.subscriberId,
                userId: editorData.payload.userId,
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    GeneralActions.IsLoading({
                      payload: false,
                    })
                  );

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: true,
                        message: 'Control configuration successful',
                      },
                    })
                  );

                  return resData;
                } else {
                  this.store.dispatch(
                    GeneralActions.IsLoading({
                      payload: false,
                    })
                  );

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message
                          ? resData.message
                          : 'An error occured',
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateControl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.UpdateControl),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocFormBuilderUrl}/Controls/update`,
              {
                ...editorData.payload,
                subscriberId: editorData.payload.subscriberId,
                userId: editorData.payload.userId,
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    GeneralActions.IsLoading({
                      payload: false,
                    })
                  );

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: true,
                        message: 'Control configuration updated successfully',
                      },
                    })
                  );

                  return resData;
                } else {
                  this.store.dispatch(
                    GeneralActions.IsLoading({
                      payload: false,
                    })
                  );

                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message
                          ? resData.message
                          : 'An error occured',
                      },
                    })
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getControlById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.GetControlById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocFormBuilderUrl}/Controls/getcontrolbyid/${authState.user.SubscriberId}/${authState.user.UserId}/${editorData.payload.id}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return EditorActions.SaveControlById({
                  payload: resData.entity,
                });
              } else {
                this.store.dispatch({
                  type: '[Editor] Failed To Get Control',
                });

                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return { type: '[Editor] Failed To Control by ID' };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  createDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.CreateDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocFormBuilderUrl}/Documents/create`,
            {
              subscriberId: authState.user.SubscriberId,
              ...editorData.payload,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                // this.store.dispatch(
                //   EditorActions.GetRecipientsByDocumentId({
                //     payload: {
                //       documentId: editorData.payload.documentId,
                //     },
                //   })
                // );

                // this.store.dispatch(
                //   EditorActions.CreateDocumentPage({
                //     payload: {
                //       subscriberName: '',
                //       name: `OnyxDoc Document Page ${Date.now()}`,
                //       displayName: `OnyxDoc Document Page ${Date.now()}`,
                //       watermark: '',
                //       pageTips: '',
                //       documentId: resData.entity.id,
                //       pageIndex: 1,
                //       pageNumber: 1,
                //       pageLayout: 1,
                //       pageControlItems: [],
                //       height: '1188px',
                //       width: '918px',
                //       position: 'relative',
                //       transform: '1',
                //       headerData: '',
                //       footerData: '',
                //     },
                //   })
                // );

                return EditorActions.SaveDocument({
                  payload: {
                    documentId: resData.entity.id,
                    file: null,
                    name: null,
                  },
                });
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message: 'Failed to create document',
                    },
                  })
                );

                return {
                  type: '[Editor] Failed To Create Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  createDocumentPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.CreateDocumentPage),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocFormBuilderUrl}/DocumentPages/create`,
              {
                subscriberId: authState.user.SubscriberId,
                ...editorData.payload,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  // this.store.dispatch(
                  //   EditorActions.GetRecipientsByDocumentId({
                  //     payload: {
                  //       documentId: editorData.payload.documentId,
                  //     },
                  //   })
                  // );

                  this.store.dispatch({
                    type: '[Editor] Create Document Page Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: 'Failed to create document page',
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Create Document Page',
                  });

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createPageControlItem$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.CreatePageControlItem),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocFormBuilderUrl}/PageControlItems/create`,
              {
                subscriberId: authState.user.SubscriberId,
                subscriberName: `${authState.user.LastName} ${authState.user.FirstName}`,
                ...editorData.payload.pageControlItem,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Create Page Control Item Was Successful',
                  });

                  return {
                    response: resData,
                    dropEvent: editorData.payload.dropEvent,
                  };
                } else {
                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: 'Failed to create page control item',
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Create Page Control Item',
                  });

                  return {
                    response: resData,
                    dropEvent: null,
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updatePageControlItem$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.UpdatePageControlItem),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocFormBuilderUrl}/PageControlItems/update`,
              {
                subscriberId: authState.user.SubscriberId,
                ...editorData.payload,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Update Page Control Item Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: 'Failed to update page control item',
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Update Page Control Item',
                  });

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deletePageControlItem$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.DeletePageControlItem),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocFormBuilderUrl}/PageControlItems/deletepagecontrolitem`,
              {
                subscriberId: authState.user.SubscriberId,
                ...editorData.payload,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: 'Page control item deleted successfully',
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Delete Page Control Item Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: 'Failed to delete page control item',
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Delete Page Control Item',
                  });

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getPageControlItemById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.GetPageControlItemById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocFormBuilderUrl}/PageControlItems/getpagecontrolitembyid/${authState.user.SubscriberId}/${editorData.payload.pagecontrolitemid}/${editorData.payload.documentId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Get Page Control item By Id Was Successfull',
                  });

                  return resData;
                } else {
                  this.store.dispatch(
                    GeneralActions.SetNotification({
                      payload: {
                        // isSuccess: false,
                        message: resData.message || resData.messages[0],
                      },
                    })
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Get Page Control item By Id',
                  });

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getPageControlItemPropertiesByPageControlItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.GetPageControlItemPropertiesByPageControlItem),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocFormBuilderUrl}/PageControlItemProperties/getpagecontrolitempropertiesbypagecontrolitem/${authState.user.SubscriberId}/${editorData.payload.pagecontrolitemid}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return EditorActions.SavePageControlItemPropertiesByPageControlItem(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                this.store.dispatch(
                  GeneralActions.SetNotification({
                    payload: {
                      // isSuccess: false,
                      message: resData.message || resData.messages[0],
                    },
                  })
                );

                return {
                  type: '[Editor] Failed To Get Page Control Item Properties By Page Control Item',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  getDocumentPages$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.GetDocumentPages),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocAlternateDocumentUrl}/ExternalApplication/getdocumentpages`,
              convertToFormData({
                ...editorData.payload,
                userId: authState.user.UserId,
                email: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              }),
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  EditorActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Editor] Get Document Pages Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Get Document Pages',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Get Document Pages ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  saveValiditySetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.SaveValiditySetting),
      withLatestFrom(this.store.select('auth')),
      // combineLatestWith(this.store.select('auth'), this.store.select('editor')),
      switchMap(([editorData, authState]) => {
        // const signingRequestId =
        //   editorState.documentsBySigningRequestId !== null
        //     ? editorState.documentsBySigningRequestId[0].document
        //         ?.signingRequestId
        //     : 0;
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/updatesigningrequestsettings`,
            {
              subscriberId: authState.user.SubscriberId,
              ...editorData.payload.data,
              userId: authState.user.UserId,
              signingRequestId: editorData.payload.signingRequestId,
            }
          )
          .pipe(
            map((resData: any) => {
              // this.store.dispatch(EditorActions.IsLoading({ payload: false }));
              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Settings Saved Successfully ',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                return {
                  type: '[Validity Setting] Saved Validity Settings Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: 'Failed To Save Settings ',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Validity Setting] Failed To Save Validity Settings',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  saveReminderSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.SaveReminderSetting),
      withLatestFrom(this.store.select('auth')),
      // combineLatestWith(this.store.select('auth'), this.store.select('editor')),
      switchMap(([editorData, authState]) => {
        // const signingRequestId =
        //   editorState.documentsBySigningRequestId !== null
        //     ? editorState.documentsBySigningRequestId[0].document
        //         ?.signingRequestId
        //     : 0;
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/updatesigningrequestsettings`,
            {
              subscriberId: authState.user.SubscriberId,
              ...editorData.payload.data,
              userId: authState.user.UserId,
              signingRequestId: editorData.payload.signingRequestId,
            }
          )
          .pipe(
            map((resData: any) => {
              // this.store.dispatch(EditorActions.IsLoading({ payload: false }));

              this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Settings Saved Successfully ',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Editor] Saved Reminder Settings Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: 'Failed To Save Settings ',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );
                return {
                  type: '[Editor] Failed To Save Reminder Settings',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleError(errorRes);
            })
          );
      })
    );
  });

  getAllDocumentCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.GetAllCategoryDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        const payload = {
          subscriberId: authState.user.SubscriberId,
          userId: authState.user.UserId,
          skip: editorData.payload.skip,
          take: editorData.payload.take,
          searchValue: null,
        };
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Category/getall/`,
            payload,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return EditorActions.SaveAllCategoryDocument({
                  payload: resData.entity.entity,
                });
              } else {
                return EditorActions.SaveAllCategoryDocument({
                  payload: [],
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Get All Document Category ${errorRes.message}`
              );
            })
          );
      }),
      share()
    );
  });

  // saveSigningRequestOnContinue$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(EditorActions.SaveSigningRequestOnContinue),
  //     withLatestFrom(this.store.select('auth')),
  //     // combineLatestWith(this.store.select('auth'), this.store.select('editor')),
  //     switchMap(([editorData, authState]) => {
  //       return this.http
  //         .post<GenericResponse>(
  //           `${environment.onyxDocDocumentUrl}/Documents/updatedocumentdata`,
  //           {
  //             subscriberId: authState.user.SubscriberId,
  //             ...editorData.payload,
  //             userId: authState.user.UserId,
  //           }
  //         )
  //         .pipe(
  //           map((resData: any) => {
  //             // this.store.dispatch(EditorActions.IsLoading({ payload: false }));

  //             // this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

  //             if (resData.succeeded === true) {
  //               const notification: Notification = {
  //                 state: 'success',
  //                 message: 'Settings Saved Successfully ',
  //               };

  //               this.notificationService.openNotification(
  //                 notification,
  //                 'flwmn-notification-success'
  //               );

  //               return {
  //                 type: '[Document] Saved Signing Request On Continue Successful',
  //               };
  //             } else {
  //               const notification: Notification = {
  //                 state: 'error',
  //                 message: 'Failed To Save Settings ',
  //               };

  //               this.notificationService.openNotification(
  //                 notification,
  //                 'flwmn-notification-error'
  //               );
  //               return {
  //                 type: '[Document] Failed To Save Signing Request On Continue',
  //               };
  //             }
  //           }),
  //           catchError((errorRes: any) => {
  //             return this.handleError(errorRes);
  //           })
  //         );
  //     })
  //   );
  // });

  getSigningRequestValiditySetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.GetSigningRequestValiditySettings),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/SigningRequests/getsigningrequestsettings/${editorData.payload.signingRequestId}/${authState.user.SubscriberId}/${authState.user.UserId}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return EditorActions.SaveSigningRequestValiditySetting({
                  payload: resData.entity,
                });
              } else {
                return EditorActions.SaveSigningRequestValiditySetting({
                  payload: null,
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Setting][CatchError] Failed To Get Signing Request Setting For Reminder And Validity ${errorRes.message}`
              );
            })
          );
      }),
      share()
    );
  });

  getSigningRequestReminderSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.GetSigningRequestReminderSettings),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocReminderUrl}/Reminder/getreminderbyobjectid?objectName=${editorData.payload.objectName}&objectId=${editorData.payload.objectId}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return EditorActions.SaveSigningRequestReminderSetting({
                  payload: resData.entity,
                });
              } else {
                return EditorActions.SaveSigningRequestReminderSetting({
                  payload: null,
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Setting Reminder][CatchError] Failed To Get Signing Request Setting For Reminder ${errorRes.message}`
              );
            })
          );
      }),
      share()
    );
  });

  getExternalUserByEmail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EditorActions.GetExternalUserByEmail),
        withLatestFrom(this.store.select('auth')),
        switchMap(([editorData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/AddressBooks/getaddressbookbyemail/${editorData.payload.email}`,

              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    EditorActions.SaveExternalUser({ payload: resData })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Editor] Failed To Get External User Info By Email',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleError(errorRes);
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  saveSignature$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditorActions.SaveSignature),
      withLatestFrom(this.store.select('auth')),
      switchMap(([editorData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AddressBooks/updatecontactsignature`,
            {
              ...editorData.payload,
              // userId: authState.user.UserId,
              // subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                return {
                  type: '[Editor] Save Signature Was Successful',
                };
              } else {
                return {
                  type: '[Editor] Save Signature Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Editor][CatchError] Failed To Save Signature ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  // getExternalSaveSignature$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(EditorActions.GetExternalSaveSignature),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([editorData, authState]) => {
  //       return this.http
  //         .get<GenericResponse>(
  //           `${environment.onyxDocDocumentUrl}/AddressBooks/getaddressbookbyemail/${editorData.payload.email}`
  //         )
  //         .pipe(
  //           map((resData: any) => {
  //             if (resData.succeeded === true) {
  //               this.store.dispatch(
  //                 EditorActions.SaveExternalSignature({
  //                   payload: resData.entity,
  //                 })
  //               );
  //             } else {
  //               this.store.dispatch(
  //                 EditorActions.SaveExternalSignature({
  //                   payload: [],
  //                 })
  //               );
  //             }
  //             return resData;
  //           }),
  //           catchError((errorRes) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[Editor][CatchError]  Failed To Save External Signature Failed ${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   );
  // });
}
